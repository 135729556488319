/* eslint-disable no-use-before-define */
import React, { useRef } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import useOutsideClick from 'hooks/useOutsideClick';
import useLocation from 'hooks/useLocation';
import Links from 'components/Navigation/Links';
import scrollToSection from 'utils/scrollToSection';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.35);
  z-index: 150;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
      & > ${Wrapper} {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
      }
    `}
`;

const Wrapper = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 14;
  visibility: hidden;
  opacity: 0;
  overflow-y: auto;
  overflow-x: hidden;
  will-change: transform;
  transition: all 0.35s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 2px 0 10px rgba(54, 54, 54, 0.2),
    -2px 0 10px rgba(54, 54, 54, 0.2);
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.dark};
  transform: translateX(100vw);
  ${({ theme }) => theme.mq.s} {
    width: 440px;
    transform: translateX(440px);
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 25px;
`;

const CloseBtn = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 4.8rem;
  transform: rotate(45deg);
  padding: 0;
  margin: 10px 20px 0 auto;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 35px;
  margin-bottom: 20px;
  background: ${({ theme }) => theme.colors.blue};
  height: 100%;
  font-size: 3.6rem;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fonts.specialFont};
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  letter-spacing: 1px;
  cursor: pointer;
`;

const Sidenav = ({ isVisible, close }) => {
  const wrapperRef = useRef(null);
  const location = useLocation();

  useOutsideClick(wrapperRef, close);

  return (
    <Overlay isVisible={isVisible}>
      <Wrapper ref={wrapperRef}>
        <CloseBtn onClick={close}>+</CloseBtn>
        <InnerWrapper>
          {location !== '/' ? (
            <Logo onClick={close} as={Link} to="/">
              POLISA
            </Logo>
          ) : (
            <Logo onClick={() => scrollToSection('#home')}>POLISA</Logo>
          )}
          <Links sidenav close={close} />
        </InnerWrapper>
      </Wrapper>
    </Overlay>
  );
};

Sidenav.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default Sidenav;
