const scrollToSection = selector => {
  if (selector) {
    if (selector.includes('.') || selector.includes('#')) {
      document
        .querySelector(`${selector}`)
        .scrollIntoView({ behavior: 'smooth' });
    }
  }
};

export default scrollToSection;
