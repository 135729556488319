import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import scrollToSection from 'utils/scrollToSection';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Icon from 'components/shared/Icon';
import emailSvg from 'assets/icons/mail.svg';
import phoneSvg from 'assets/icons/phone.svg';
import useLocation from 'hooks/useLocation';

const LinkItem = styled.li`
  position: relative;
  display: none;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 2rem;
  cursor: pointer;
  transition: 0.3s;
  margin: 20px 0;
  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.blue100};
      &:after {
        transform: translateX(-50%) scaleX(1);
        ${({ theme }) => theme.mq.xl} {
          transform: scaleX(1);
        }
      }
    }
  }
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -10px;
    width: 80px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.blue100};
    transition: 0.3s;
    transform: translateX(-50%) scaleX(0);
    transform-origin: 50% 50%;
    ${({ theme }) => theme.mq.xl} {
      width: 50px;
      left: 0;
      transform-origin: 0 50%;
      transform: scaleX(0);
    }
  }
  ${({ theme }) => theme.mq.xl} {
    display: block;
    margin: 0 25px;
  }
`;

const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mq.xl} {
    flex-direction: row;
  }
  ${({ isSidenav }) =>
    isSidenav &&
    css`
      & > ${LinkItem} {
        display: block !important;
      }
    `}
`;

const StyledLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

const ContactLinkItem = styled(LinkItem)`
  display: none;
  text-decoration: none;
  ${({ theme }) => theme.mq.md} {
    height: 100%;
    align-self: flex-end;
    align-items: center;
    display: flex !important;
    border-left: 1px solid ${({ theme }) => theme.colors.gray};
    @media (hover: hover) {
      &:hover {
        background: ${({ theme }) => theme.colors.blue200};
        color: ${({ theme }) => theme.colors.dark};
      }
    }
    &:after {
      display: none;
    }
  }
  ${({ theme }) => theme.mq.md} {
    margin: 0 0 0 auto !important;
  }
  ${({ isSidenav }) =>
    isSidenav &&
    css`
      display: flex !important;
      ${({ theme }) => theme.mq.md} {
        display: none !important;
      }
    `}
  span {
    white-space: no-wrap;
  }
`;

const StyledContactLinkItem = styled(ContactLinkItem)`
  white-space: no-wrap;
`;

const ContactLink = styled.div`
  display: flex;
  align-items: center;
  padding: 0 40px;
  transition: 0.3s;
  font-size: 1.8rem;
  & > span {
    margin-left: 15px;
  }
`;

const LinkContent = styled.span`
  white-space: nowrap;
  display: block;
  ${({ theme }) => theme.mq.md} {
    display: none;
  }
  ${({ theme }) => theme.mq.lg} {
    display: block;
  }
  ${({ theme }) => theme.mq.xl} {
    display: none;
  }
  @media (min-width: 1340px) {
    display: block;
  }
`;

const Links = ({ sidenav, close }) => {
  const location = useLocation();
  return (
    <>
      <List isSidenav={sidenav}>
        <LinkItem onClick={() => close('#about')}>
          {location !== '/' ? (
            <StyledLink as={AnchorLink} to="/#about" title="O nas" stripHash />
          ) : (
            <StyledLink onClick={() => scrollToSection('#about')}>
              O nas
            </StyledLink>
          )}
        </LinkItem>
        <LinkItem onClick={() => close('#process')}>
          {location !== '/' ? (
            <StyledLink
              as={AnchorLink}
              to="/#process"
              title="Proces"
              stripHash
            />
          ) : (
            <StyledLink onClick={() => scrollToSection('#process')}>
              Proces
            </StyledLink>
          )}
        </LinkItem>
        <LinkItem onClick={() => close('#services')}>
          {location !== '/' ? (
            <StyledLink
              as={AnchorLink}
              to="/#services"
              title="Usługi"
              stripHash
            />
          ) : (
            <StyledLink onClick={() => scrollToSection('#services')}>
              Usługi
            </StyledLink>
          )}
        </LinkItem>
        <LinkItem onClick={() => close('#contact')}>
          {location !== '/' ? (
            <StyledLink
              as={AnchorLink}
              to="/#contact"
              title="Kontakt"
              stripHash
            />
          ) : (
            <StyledLink onClick={() => scrollToSection('#contact')}>
              Kontakt
            </StyledLink>
          )}
        </LinkItem>
      </List>
      <StyledContactLinkItem
        isSidenav={sidenav}
        as="a"
        href="tel:+48 888 219 451"
      >
        <ContactLink>
          <Icon size={27} src={phoneSvg} alt="phone" />
          <LinkContent>Zadzwoń +48 888 219 451</LinkContent>
        </ContactLink>
      </StyledContactLinkItem>
      <ContactLinkItem
        isSidenav={sidenav}
        as="a"
        href="mailto: polisa@koszalinubezpieczenia.pl"
      >
        <ContactLink>
          <Icon src={emailSvg} alt="email" />
          <LinkContent>polisa@koszalinubezpieczenia.pl</LinkContent>
        </ContactLink>
      </ContactLinkItem>
    </>
  );
};

Links.propTypes = {
  sidenav: PropTypes.bool,
  close: PropTypes.func,
};

Links.defaultProps = {
  close: () => null,
  sidenav: false,
};

export default Links;
