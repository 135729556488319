import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Wrapper = styled.footer`
  padding: 20px 0;
  background: ${({ theme }) => theme.colors.blue};
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${({ theme }) => theme.mq.s} {
    flex-direction: row;
  }
`;

const Text = styled.p`
  display: block;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.regular};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-family: ${({ theme }) => theme.fonts.subFont};
  ${({ theme }) => theme.mq.s} {
    &:first-child {
      &:after {
        content: '|';
        padding: 0 4px;
      }
    }
  }
`;

const StyledText = styled(Text)`
  padding-bottom: 12px;
  order: -1;
  transition: 0.2s;
  ${({ theme }) => theme.mq.s} {
    padding-bottom: 0;
    order: 0;
  }
  @media (hover: hover) {
    &:hover {
      color: #578feb;
    }
  }
`;

const Footer = () => {
  const footerText = `© Copyright 2020 koszalinubezpieczenia.pl. Wszelkie prawa zastrzeżone.`;

  return (
    <Wrapper>
      <InnerWrapper>
        <Text>{footerText}</Text>
        <StyledText as={Link} to="/polityka-prywatnosci">
          Polityka Prywatności
        </StyledText>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Footer;
