import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Icon from 'components/shared/Icon';
import menuIcon from 'assets/icons/menu.svg';
import Links from 'components/Navigation/Links';
import Sidenav from 'components/Navigation/Sidenav';
import useMedia from 'hooks/useMedia';
import useLocation from 'hooks/useLocation';
import scrollToSection from 'utils/scrollToSection';

const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  height: ${({ theme }) => theme.navSize.mobile};
  z-index: 100;
  transform: ${({ navHidden, theme }) =>
    navHidden ? `translate3d(0, -${theme.navSize.mobile}, 0)` : 'none'};
  transition: all ease-in-out 250ms;
  box-shadow: 0 -1px ${({ theme }) => theme.colors.gray} inset;
  ${({ theme }) => theme.mq.lg} {
    height: ${({ theme }) => theme.navSize.desktop};
    transform: ${({ navHidden, theme }) =>
      navHidden ? `translate3d(0, -${theme.navSize.desktop}, 0)` : 'none'};
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinksWrapper = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.mq.s} {
    display: flex;
    justify-content: space-evenly;
  }
`;

const MenuButton = styled.button`
  background: transparent;
  cursor: pointer;
  margin-right: 10px;
  ${({ theme }) => theme.mq.xl} {
    display: none;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  width: 185px;
  padding: 0 35px;
  background: ${({ theme }) => theme.colors.blue};
  height: 100%;
  font-size: 3.6rem;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fonts.specialFont};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  letter-spacing: 1px;
  text-decoration: none;
`;

const Navigation = () => {
  const [isNavHidden, setIsNavHidden] = useState(false);
  const [isSidenavVisible, setIsSidenavVisible] = useState(false);
  const [scrollSection, setScrollSection] = useState('home');
  const location = useLocation();

  useEffect(() => {
    location === '/' && scrollToSection(scrollSection);
    setIsNavHidden(false);
  }, [location]);

  let prevScroll = 0;

  const handleScroll = e => {
    const window = e.currentTarget;

    if (prevScroll > window.scrollY) {
      setIsNavHidden(false);
    } else if (window.scrollY >= 100) {
      setIsNavHidden(true);
    }
    prevScroll = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', e => handleScroll(e));

    return () => {
      window.removeEventListener('scroll', e => handleScroll(e));
    };
  }, []);

  const hideNav = () => {
    if (window.scrollY >= 100) setIsNavHidden(true);
  };

  const closeSidenav = () => {
    setIsSidenavVisible(false);
    setTimeout(hideNav, 800);
    document.body.style.overflow = 'auto';
  };

  const openSidenav = () => {
    setIsSidenavVisible(true);
    document.body.style.overflow = 'hidden';
  };

  const handleClose = section => {
    setTimeout(hideNav, 800);
    setScrollSection(section);
  };

  const matchesXL = useMedia('(min-width: 1200px)');

  return (
    <>
      {!matchesXL && (
        <Sidenav
          isVisible={isSidenavVisible}
          close={section => {
            closeSidenav();
            handleClose(section);
          }}
        />
      )}
      <Wrapper navHidden={isNavHidden}>
        <InnerWrapper>
          {location !== '/' ? (
            <Logo as={Link} to="/">
              POLISA
            </Logo>
          ) : (
            <Logo onClick={() => scrollToSection('#home')}>POLISA</Logo>
          )}
          <LinksWrapper>
            <Links close={section => handleClose(section)} />
          </LinksWrapper>
          {!matchesXL && (
            <MenuButton aria-label="menu" onClick={openSidenav}>
              <Icon size={40} src={menuIcon} />
            </MenuButton>
          )}
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

export default Navigation;
