const breakpoints = {
  xs: 440,
  s: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1410,
};

export const theme = {
  fonts: {
    mainFont: `'Roboto', sans-serif`,
    subFont: `'Poppins', sans-serif`,
    specialFont: `'Caladea', sans-serif`,
  },
  colors: {
    white: '#ffffff',
    dark: '#282828',
    dark100: '#555a6d',
    blue: '#0b193e',
    blue100: '#2f4b9e',
    blue200: '#d1e3ff',
    red: '#E30613',
    green: '#57c766',
    red100: '#ed395d',
    gray: '#dddddd',
    gray100: '#eaeaea',
    gray200: '#EEEEEE',
    gray300: '#BFBFBF',
  },
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  fontSize: {
    xs: '1.1rem',
    s: '1.4rem',
    m: '1.6rem',
    md: '1.9rem',
    lg: '2.2rem',
    lgm: '2.6rem',
    xl: '3rem',
    xlg: '3.6rem',
    xxl: '6rem',
    xxxl: '7.2rem',
  },
  navSize: {
    desktop: '95px',
    mobile: '80px',
  },
  mq: Object.keys(breakpoints).reduce((acc, breakpoint) => {
    acc[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]}px)`;
    return acc;
  }, {}),
};
